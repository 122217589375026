(function () {
    function supports() {
        // Holds css properties/values to check for support.
        const cssRequiredSupport = '(--css: variables) and (object-fit: contain)';

        // Holds JS methods to check for support
        const jsRequiredSupport = Array.prototype.includes && window.fetch;

        return Boolean(window.CSS && window.CSS.supports && CSS.supports(cssRequiredSupport) && jsRequiredSupport);
    }

    if (!supports()) {
        const template = `<div style="z-index:2000" class="fixed top-0 right-0 bottom-0 left-0 bg-light-2 flex align-middle justify-center sm-items-start sm-p4 overflow-auto">
            <div class="bg-brand-white box-shadow flex flex-column p2 sm-p3 sm-mt4 center sm-col-10">
                <div class="icon-4xl critical-failure-bg mx-auto my3"></div>
                <h1 class="h2 regular mb2">Your browser is not supported anymore</h1>
                <p class="mb2">To fully experience all the available features, use one of the following browsers</p>
                <ul class="list-reset">
                    <li class="inline-block">
                        <a href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en"
                           title="Link to Chrome browser page"
                           target="_blank"
                           class="m1">
                            <svg class="icon-3xl">
                                <use class="fill-disabled" xlink:href="#icon-chrome"></use>
                            </svg>
                        </a>
                    </li>
                    <li class="inline-block">
                        <a href="https://www.mozilla.org/en-US/firefox/new/"
                           title="Link to Firefox browser page"
                           target="_blank"
                           class="m1">
                            <svg class="icon-3xl">
                                <use class="fill-disabled" xlink:href="#icon-firefox"></use>
                            </svg>
                        </a>
                    </li>
                    <li class="inline-block">
                        <a href="https://www.microsoft.com/en-us/windows/microsoft-edge"
                           title="Link to Microsoft Edge browser page"
                           target="_blank"
                           class="m1">
                            <svg class="icon-3xl">
                                <use class="fill-disabled" xlink:href="#icon-microsoft-edge"></use>
                            </svg>
                        </a>
                    </li>
                    <li class="inline-block">
                        <a href="https://support.apple.com/downloads/safari"
                           title="Link to Safari browser page"
                           target="_blank"
                           class="m1">
                            <svg class="icon-3xl">
                                <use class="fill-disabled" xlink:href="#icon-safari"></use>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>`;

        const fragment = window.document.createRange().createContextualFragment(template);

        window.document.addEventListener('DOMContentLoaded', () => {
            window.document.body.appendChild(fragment);
        });
    }

    window.EVERON = window.EVERON || {};
    window.EVERON.supports = supports;
})();
