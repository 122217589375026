import StackdriverErrorReporter from 'stackdriver-errors-js';

(function () {
    if (!window.EVERON.supports()) {
        return;
    }

    const projectIds = {
        local: 'everon-dev',
        test: 'everon-test',
        test2: 'everon-test2',
        test3: 'everon-test3',
        test4: 'everon-test4',
        test5: 'everon-test5',
        test6: 'everon-test6',
        test7: 'everon-test7',
        test8: 'everon-test8',
        staging: 'everon-staging',
        production: 'everon-eu'
    };

    // Get the domain, on mobile is empty, hence we get from tenantUrl. This still doesn't feel robust
    const subDomain = window.location.hostname ? window.location.hostname.split('.')[0] : window.EVERON.tenantUrl.split('//')[1].split('.')[0];
    const projectId = projectIds[subDomain] || projectIds.production;

    // errorReporting is initialized only if devMode is true or if the env is production
    if (localStorage.devMode === 'true' || projectId === projectIds.production) {
        fetch(`${window.EVERON.apiUrl}/api/platform/google-stackdriver-key`).then(response => response.json())
                                                                            .then(data => initErrorHandler(data.key));
    }

    /**
     * Inits stackdriver lib
     * @param {string} key
     */
    function initErrorHandler(key) {
        const errorHandler = new StackdriverErrorReporter();

        errorHandler.start({
            key,
            projectId
        });

        window.EVERON = window.EVERON || {};
        window.EVERON.errorHandler = errorHandler;
    }
})();
